/*------ 22. contact Page  ------*/

.contact-map {
  position: relative;

  height: 560px;
  @media #{$xs-layout} {
    height: 400px;
  }
}

.contact-info-wrap {
  padding: 15px 70px 116px 90px;
  border-radius: 24px;
  background-color: #fff;
  @media #{$lg-layout} {
    padding: 0px 20px 116px 40px;
  }
  @media #{$md-layout} {
    padding: 0px 20px 116px 30px;
  }
  @media #{$xs-layout} {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0px 20px 45px 30px;
  }
  .single-contact-info {
    display: flex;
    align-items: center;

    margin-bottom: 40px;
    .contact-icon {
      margin-right: 20px;
      i {
        font-size: 20px;
        line-height: 40px;

        display: inline-block;

        width: 40px;
        height: 40px;

        transition: all 0.3s ease 0s;
        text-align: center;

        color: #252525;
        border: 1px solid #252525;
        border-radius: 100%;
      }
    }
    .contact-info-dec {
      p {
        line-height: 1;

        margin: 0 0 9px;

        color: #404040;
        a {
          color: #404040;
          &:hover {
            color: $theme-color;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    &:hover .contact-icon i {
      color: #fff;
      background-color: #252525;
    }
  }

  .radio{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    input {
      display: flex;
      float: left;
      margin:0;
      width: 15px;
      height: 15px;

      border: 1px solid #9fa0a2;
    }
     label{   
      display:inline;
      vertical-align:top;
   }
    span {
      font-weight: 400;

      margin: 0 0 0 12px;

      color: #333;
    }
    li{
      padding: 5px
    }
  }

  .checkbox{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    
    input[type="checkbox"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;
    
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
    
      display: grid;
      place-content: center;
    }
    
    input[type="checkbox"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }
    
    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    
    input[type="checkbox"]:focus {
      outline: max(2px, 0.15em) solid currentColor;
      outline-offset: max(2px, 0.15em);
    }
    
    input[type="checkbox"]:disabled {
      --form-control-color: var(--form-control-disabled);
    
      color: var(--form-control-disabled);
      cursor: not-allowed;}
     label{   
      
   }
    span {
      font-weight: 400;

      margin: 0 0 0 12px;

      color: #333;
    }
    li{
      padding: 5px
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: #000;
  }
  .contact-info{
    input{
      font-size: 14px;
      border-radius: 7px;

      height: 45px;
      padding: 2px 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      background: transparent none repeat scroll 0 0;
    }
    input.contact-address {
      margin-bottom: 10px;

    }
  }
  .contact-select {
    label {
      margin: 0 0 7px;

      color: #000;
    }
    input {
      font-size: 14px;

      padding-right: 10px;
      padding-left: 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      border-radius: 7px;
      background: transparent none repeat scroll 0 0;
    }
    
  }
  .contact-select {
    select {
      font-size: 14px;
      border-radius: 7px;

      height: 45px;
      padding: 2px 20px;

      cursor: pointer;

      color: #333;
      border: 1px solid #e6e6e6;

      -webkit-appearance: none;
         -moz-appearance: none;
    }
  }
  .additional-info-wrap {
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
    .additional-info {
      label {
        font-size: 14px;

        margin: 0 0 7px;

        color: #333;
      }
      textarea {
        font-size: 14px;
        border-radius: 7px;

        height: 138px;
        padding: 17px 20px;

        color: #333;
        border: 1px solid #e6e6e6;
        background: transparent none repeat scroll 0 0;
      }
    }
  }

  
  .place-order {
    font-weight: 500;
    line-height: 1;

    z-index: 9;

    display: block;

    width: 100%;
    padding: 18px 20px;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    border: none;
    border-radius: 50px;
    background: none;
    background-color: $theme-color;
    &:hover {
      background-color: #333;
    }
  }
}

.contact-social {
  margin-top: 58px;
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    margin: 0 0 17px;

    color: #4d4d4d;
  }
  ul {
    li {
      display: inline-block;

      margin: 0 10px;
      a {
        font-size: 16px;

        color: #4d4d4d;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.contact-form {
  padding: 0px 110px 50px 110px;

  background-color: #fff;
  @media #{$lg-layout} {
    padding: 50px 50px 50px 50px;
  }
  @media #{$md-layout} {
    padding: 50px 30px 50px 30px;
  }
  @media #{$xs-layout} {
    padding: 50px 30px 50px 30px;
  }
  .contact-title {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 36px;

      color: #464646;
    }
  }
  .contact-form-style {
    input,
    textarea {
      font-size: 14px;
      border-radius: 7px;

      height: 45px;
      padding: 2px 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      background: transparent none repeat scroll 0 0;
    }
    textarea {
      height: 175px;
      margin-bottom: 0;
      padding: 20px 14px;
    }
    button {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      border-radius: 8px;
      overflow: hidden;
      margin-top: 38px;
      padding: 15px 52px;

      text-transform: uppercase;

      color: #fff;
      border: none;
      background-color: #404040;
      &:hover {
        background-color: $theme-color;
      }
    }
  }
  p {
    color: #333;
    &.success {
      margin-top: 10px;
    }
  }
}

.contact-form-style {
  .row {
    & div[class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
.contact-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
