/*------- 21. Checkout page  -----------*/

.billing-info-wrap {
  .radio {
    align-items: center;
    justify-content: start;

    display: flex;

    input[type="radio"] {
      /* remove standard background appearance */

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      display: inline-block;

      width: 20px;
      height: 20px;
      padding: 4px;
      /* background-color only for content */
      background-clip: content-box;
      border: 2px solid #bbbbbb;
      background-color: #e7e6e7;
      border-radius: 50%;
    }

    /* appearance for checked radiobutton */
    input[type="radio"]:checked {
      background-color: $theme-color;
    }
    input[type="radio"]:disabled {
      pointer-events: none;
      opacity: 0.5;
      
    }
    input[type="radio"]:hover {
      background-color: $theme-color;
    }

    label {
      justify-self: center;
      margin-top: 10px;
      margin-left: 2px;
     
    }
    
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    input[type="checkbox"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: #9a9a9a;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid #9a9a9a;
      border-radius: 0.3em;
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: inset(0.1em);

      //clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      // box-shadow: inset 1em 1em var(--form-control-color);
      /* Windows High Contrast Mode */
      background-color: $theme-color;
      color: $theme-color;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }

    input[type="checkbox"]:focus {
      outline: max(2px, 0.15em) solid currentColor;
      outline-offset: max(2px, 0.15em);
    }

    input[type="checkbox"]:disabled {
      --form-control-color: var(--form-control-disabled);

      color: var(--form-control-disabled);
      cursor: not-allowed;
    }
    label {
      margin-left: 4px;
    }
    span {
      font-weight: 400;

      margin: 0 0 0 12px;

      color: #333;
    }
    li {
      padding: 5px;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: #000;
  }
  .billing-info {
    input {
      font-size: 14px;
      border-radius: 7px;

      height: 45px;
      padding: 2px 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      background: transparent none repeat scroll 0 0;
    }
    input.billing-address {
      margin-bottom: 10px;
    }
    button {
      font-weight: 500;
      line-height: 1;

      z-index: 9;

      display: block;

      width: 80%;
      padding: 1px 1px;

      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;

      color: $theme-color;
      border: none;
      border-radius: 20px;
      border: solid 1px $theme-color;
      background: none;
      // background-color: $theme-color;
      &:hover {
        background-color: #333;
        color: #fff;
      }
    }
  }
  .billing-select {
    label {
      margin: 0 0 7px;

      color: #000;
    }
    input {
      font-size: 14px;

      padding-right: 10px;
      padding-left: 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      border-radius: 7px;
      background: transparent none repeat scroll 0 0;
    }
  }
  .billing-select {
    select {
      font-size: 14px;
      border-radius: 7px;

      height: 45px;
      padding: 2px 20px;

      //background: rgba(0, 0, 0, 0) url("../../assets/img/icon-img/select.png") no-repeat scroll right 18px center;

      cursor: pointer;

      color: #333;
      border: 1px solid #e6e6e6;

      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }
  .checkout-account {
    display: flex;
    align-items: center;
    input {
      display: inline-block;
      float: left;

      width: 10px;
      height: 10px;

      border: 1px solid #9fa0a2;
    }
    span {
      font-weight: 400;

      margin: 0 0 0 12px;

      color: #333;
    }
  }
  .checkout-account-toggle {
    input {
      font-size: 14px;

      margin: 0 0 20px;
      padding-right: 10px;
      padding-left: 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      background: transparent none repeat scroll 0 0;
    }
    button.checkout-btn {
      font-weight: 500;

      z-index: 9;

      padding: 10px 30px;

      cursor: pointer;
      text-transform: uppercase;

      color: #fff;
      border: medium none;
      border-radius: 50px;
      background-color: $theme-color;
      &:hover {
        background-color: #333;
      }
    }
  }
  .additional-info-wrap {
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
    .additional-info {
      label {
        font-size: 14px;

        margin: 0 0 7px;

        color: #333;
      }
      textarea {
        font-size: 14px;
        border-radius: 7px;

        height: 138px;
        padding: 17px 20px;

        color: #333;
        border: 1px solid #e6e6e6;
        background: transparent none repeat scroll 0 0;
      }
    }
  }
  .different-address {
    display: none;
  }
}

.your-order-area {
  @media #{$md-layout} {
    margin-top: 30px;
  }
  @media #{$xs-layout} {
    margin-top: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: #000;
  }
  .your-order-wrap {
    padding: 38px 45px 44px;

    background: #f6f6f6;
    // border: $theme-color solid 1px;
    border-radius: 12px;
    @media #{$lg-layout} {
      padding: 30px 20px 36px;
    }
    @media #{$xs-layout} {
      padding: 30px 20px 36px;
    }
    .your-order-product-info {
      .your-order-top {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;
          }
        }
      }
      .your-order-middle {
        margin: 29px 0;
        padding: 19px 0 18px;

        border-top: 1px solid #dee0e4;
        border-bottom: 1px solid #dee0e4;
        ul {
          li {
            display: flex;
            justify-content: space-between;

            margin: 0 0 15px;
          }
        }
      }
      .your-order-bottom {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-size: 14px;
            font-weight: 400;

            list-style: none;
          }
          li.your-order-shipping {
            font-size: 16px;
            font-weight: 400;

            color: #212121;
          }
          li.your-order-discount {
            font-size: 16px;
            font-weight: 500;
            color: $theme-color;
          }

          li.your-order-discount-description {
            font-size: 13px;
            font-weight: 300;
          }
        }
      }
      .your-order-total {
        margin: 18px 0 33px;
        padding: 17px 0 19px;

        border-top: 1px solid #dee0e4;
        border-bottom: 1px solid #dee0e4;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li.order-total {
            font-size: 18px;
            font-weight: 500;

            color: #212121;
          }
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $theme-color;
          }
        }
      }
    }
  }

  .payment-accordion {
    margin: 0 0 16px;
    &:last-child {
      margin: 0 0 0;
    }
    h4 {
      font-size: 16px;

      margin: 0;

      color: #212121;
      a {
        position: relative;

        display: block;

        color: #212121;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .panel-body {
      padding: 5px 0 0 0;
      p {
        font-size: 14px;

        padding: 0 0 0 10px;

        color: #333;
      }
    }
  }
  .place-order > a,
  .place-order > button {
    font-weight: 500;
    line-height: 1;

    z-index: 9;

    display: block;

    width: 100%;
    padding: 18px 20px;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    border: none;
    border-radius: 50px;
    background: none;
    background-color: $theme-color;
    &:hover {
      background-color: #333;
    }
  }
}

.checkout-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.tooltipp {
  position: relative; /* making the .tooltip span a container for the tooltip text */
}
.tooltipp:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;

  display: none; /* hide by default */
}

.tooltipp:hover:before {
  display: block;
}
